import { useState } from "react";
import { Translation, useTranslation } from "react-i18next";
import { Chip } from "components/Chip";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "components/DropdownSelect";
import { GlobeUnfilled } from "components/Icons";
import * as Dialog from "@radix-ui/react-dialog";

type LngKey = "en" | "my" | "zh";
type Lngs = Record<LngKey, { label: string }>;
type UseLanguageChangeData = {
    handleLanguageChange: (lng: string) => void;
    language: string;
    ready: boolean;
};

const lngs: Lngs = {
    en: { label: "EN" },
    my: { label: "BM" },
    zh: { label: "中文" },
};

const LanguageSelector = (): JSX.Element => {
    const { handleLanguageChange, language } = useLanguageChange();
    return (
        <div className="flex gap-1">
            {Object.keys(lngs).map((lng) => (
                <Chip
                    key={lng}
                    className="cursor-pointer text-tiny"
                    variant={lng === language ? "selected" : "default"}
                    onClick={() => handleLanguageChange(lng)}
                >
                    {lngs[lng].label}
                </Chip>
            ))}
        </div>
    );
};
export default LanguageSelector;

const useLanguageChange = (): UseLanguageChangeData => {
    const { i18n, ready } = useTranslation();
    const handleLanguageChange = (lng: string): void =>
        void i18n.changeLanguage(lng);

    return {
        handleLanguageChange,
        language: i18n.language || "en",
        ready,
    };
    lngs;
};

export const LanguageDropdown = (): JSX.Element => {
    const { handleLanguageChange, language } = useLanguageChange();
    const otherLanguage = Object.keys(lngs).filter((lng) => language != lng);

    return (
        <Select value={language} onValueChange={handleLanguageChange}>
            <SelectTrigger className="h-[42px] w-[104px] border-transparent bg-white p-2.5 text-blue-grey-900 hover:bg-primary-50 hover:text-primary-600 data-[state=closed]:rounded-[20px] data-[state=open]:rounded-t-[20px] data-[state=open]:bg-primary-50 data-[state=open]:text-primary-600">
                <SelectValue>
                    <div className="line-clamp-1 flex items-center gap-1 text-inherit">
                        <GlobeUnfilled className="size-5 text-blue-grey-600" />
                        {lngs[language].label}
                    </div>
                </SelectValue>
            </SelectTrigger>
            <SelectContent
                side="bottom"
                sideOffset={-5}
                className="w-[104px] rounded-b-[20px] bg-white data-[state=open]:shadow-lg"
            >
                <SelectGroup>
                    {otherLanguage.map((lng) => (
                        <SelectItem
                            key={lng}
                            value={lng}
                            className="h-[41px] pl-9 text-blue-grey-900 hover:bg-primary-50 hover:text-primary-600"
                        >
                            {lngs[lng].label}
                        </SelectItem>
                    ))}
                </SelectGroup>
            </SelectContent>
        </Select>
    );
};

const lngsPopup: Lngs = {
    en: { label: "English" },
    my: { label: "Malay" },
    zh: { label: "中文" },
};

export const LanguagePopup = (): JSX.Element => {
    const [open, setOpen] = useState(false);
    const { handleLanguageChange, language } = useLanguageChange();
    const languages = Object.keys(lngs);
    return (
        <Dialog.Root open={open} onOpenChange={setOpen}>
            <Dialog.Trigger asChild>
                <div className="h-[28x] w-[60px] rounded-[20px] border-transparent bg-white px-1 py-1.5 text-blue-grey-900 hover:bg-primary-50 hover:text-primary-600 data-[state=open]:bg-primary-50 data-[state=open]:text-primary-600">
                    <div className="typography-tiny line-clamp-1 flex items-center gap-1">
                        <GlobeUnfilled className="size-5 text-blue-grey-600" />
                        {lngs[language].label}
                    </div>
                </div>
            </Dialog.Trigger>
            <Dialog.Portal>
                <Dialog.Overlay className="fixed inset-0 z-20 bg-black/40" />
                <Dialog.Content className="fixed inset-1/2 z-20 flex h-max max-h-[60%] w-max min-w-72 max-w-[80%] -translate-x-1/2 -translate-y-1/2 flex-col overflow-y-auto rounded-xl bg-white transition transition-none ease-in-out data-[state=closed]:duration-400 data-[state=open]:duration-300 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] lg:max-h-[80%] lg:max-w-[628px]">
                    <div className="flex flex-col divide-x-0 divide-y-0.5 divide-solid divide-blue-grey-50">
                        <Dialog.Title className="typography-h4 pb-5 pt-8 text-center text-blue-grey-900">
                            <Translation ns="components/LanguageSelector">
                                {(t) =>
                                    t(
                                        "preferredLanguage",
                                        "Select your preferred language",
                                    )
                                }
                            </Translation>
                        </Dialog.Title>
                        {languages.map((lng) => (
                            <button
                                key={lng}
                                onClick={() => {
                                    handleLanguageChange(lng);
                                    setOpen(false);
                                }}
                                className="typography-h4 border-none bg-transparent py-2 text-blue-grey outline-none focus-visible:outline-none"
                            >
                                {lngsPopup[lng].label}
                            </button>
                        ))}
                    </div>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );
};
